// Import React
import React from 'react'
import {hot} from 'react-hot-loader/root'
// Import Spectacle Core tags
import {
  BlockQuote,
  Cite,
  Deck,
  Heading,
  Image,
  List,
  ListItem,
  Notes,
  Quote,
  Slide,
  Text,
  Appear,
  S,
  CodePane,
} from 'spectacle'
import {
  ExampleExtraction,
  ExampleExtractionICU,
  ExampleExtractionPlural,
  ExampleLoading,
} from './history'
import styled from 'react-emotion'

// Import theme
import createTheme from 'spectacle/lib/themes/default'

const images = {
  // formidagon: require('../assets/formidable-logo.svg'),
  // goodWork: require('../assets/good-work.gif'),
  rage: require('../assets/computer-rage.gif'),
  simplify: require('../assets/simplify.gif'),
  obstacles: require('../assets/remove-obstacles.gif'),
  magic: require('../assets/what-kind-of-magic-is-this.jpg'),
  showMe: require('file-loader!../assets/show-me.mp4'),
  biceps: require('../assets/biceps.png'),
  summit: require('../assets/summit.png'),
  intro1: require('../assets/zen-intro-1.png'),
  intro2: require('../assets/zen-intro-2.png'),
  intro3: require('../assets/zen-intro-3.png'),
  outro1: require('../assets/zen-outro-1.png'),
  outro2: require('../assets/zen-outro-2.png'),
  outro3: require('../assets/zen-outro-3.png'),
  zendesk: require('../assets/zendesk-logo.png'),
}

// Require CSS
require('normalize.css')

const baseTheme: any = createTheme(
  {
    // SUMMIT COLOR:
    secondary: '#14343C',
    // ALGAE COLOR:
    primary: '#accfcb',
    quaternary: 'white',
    // SELL GOLD COLOR:
    tertiary: '#CEAE6A',

    // // SUMMIT COLOR:
    // quaternary: '#14343C',
    // // ALGAE COLOR:
    // secondary: '#accfcb',
    // primary: 'white',
    // // SELL GOLD COLOR:
    // tertiary: '#CEAE6A',

    // primary: 'white',
    // secondary: '#1F2022',
    // tertiary: '#03A9FC',
  },
  {
    primary: 'Sharp Sans No1',
    secondary: 'Proxima Nova',
  },
)

const theme = {
  ...baseTheme,
  screen: {
    ...baseTheme.screen,
    components: {
      ...baseTheme.screen.components,
      text: {
        ...baseTheme.screen.components.text,
        fontSize: '1.5rem',
      },
      heading: {
        ...baseTheme.screen.components.heading,
        h1: {
          ...baseTheme.screen.components.heading.h1,
          fontSize: '5.0rem',
        },
        h2: {
          ...baseTheme.screen.components.heading.h2,
          fontSize: '4.0rem',
        },
        h3: {
          ...baseTheme.screen.components.heading.h3,
          fontSize: '3.0rem',
          fontWeight: '600',
        },
        h4: {
          ...baseTheme.screen.components.heading.h4,
          fontSize: '2.5rem',
        },
        h5: {
          ...baseTheme.screen.components.heading.h5,
          fontSize: '1.8rem',
        },
        h6: {
          ...baseTheme.screen.components.heading.h6,
          fontSize: '1.3rem',
        },
      },
    },
  },
}

const GridSlide = styled(Slide)`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 50% 50%;
  grid-template-areas:
    /* 'header header' */
    'left-top right'
    'left-bottom right'
    /* 'footer footer' */;
  height: 80%;
`

const IntroSlide = styled(Slide)`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 33% 33% 33%;
  grid-template-areas:
    /* 'header header' */
    'left middle right'
    /* 'left middle right' */
    /* 'footer footer' */;
  height: 80%;
`

const LeftPart = styled('div')`
  grid-area: left;
  /* align-self: start; */
`
const MiddlePart = styled('div')`
  grid-area: middle;
  /* align-self: start; */
`

const LeftTopPart = styled('div')`
  grid-area: left-top;
  align-self: end;
`
const LeftBottomPart = styled('div')`
  grid-area: left-bottom;
  align-self: start;
`

const RightPart = styled('div')`
  grid-area: right;
  /* align-self: center; */
`

// next step:
// create wireframe presentation
// use placeholders for history parts

if (window.location.hash.includes('presenter')) {
  document.body.style.zoom = '50%'
  document.body.style.fontSize = '400%'
}

export const Presentation = hot(() => (
  // @ts-ignore
  <Deck
    transition={['fade']} // 'zoom', 'slide'
    transitionDuration={500}
    theme={theme}
    contentWidth={1100}
    // disableKeyboardControls
  >
    <GridSlide transition={['fade']} bgColor="primary">
      <LeftTopPart>
        <Heading size={3} lineHeight={1} textAlign="left" textColor="secondary">
          How we made i18n
          <br />
          your friend
        </Heading>
      </LeftTopPart>

      <LeftBottomPart>
        <Heading
          size={5}
          margin="10px 0 0"
          bold={false}
          textColor="secondary"
          textAlign="left"
          textFont="secondary"
        >
          Bazyli Brzóska / @niieani
        </Heading>
        <Heading
          size={5}
          margin="10px 0 0"
          bold={false}
          textColor="secondary"
          textAlign="left"
          textFont="secondary"
        >
          April 10th, 2019 - 14:30
        </Heading>
        <Heading
          size={5}
          margin="10px 0 0"
          textColor="secondary"
          bold
          textAlign="left"
          textFont="secondary"
        >
          T5: Productivity
        </Heading>
      </LeftBottomPart>
      <RightPart>
        <Image src={images.summit} height="80%" />
      </RightPart>
      <Notes>
        <ol>
          <li>Welcome</li>
          <li>caught a cold</li>
        </ol>
      </Notes>
    </GridSlide>
    <IntroSlide bgColor="quaternary">
      <LeftPart>
        <Image src={images.intro1} />
      </LeftPart>

      <MiddlePart>
        <Image src={images.intro2} />
      </MiddlePart>

      <RightPart>
        <Image src={images.intro3} />
      </RightPart>
      <Notes>
        <ol>
          <li>Ever felt like this?</li>
        </ol>
      </Notes>
    </IntroSlide>
    <Slide bgColor="secondary">
      <Image src={images.rage} width={800} />
      <Notes>
        <ol>
          <li>What triggered this feeling?</li>
          <li>UX makes the difference</li>
        </ol>
      </Notes>
    </Slide>
    {/* <Slide transition={['fade']} bgColor="secondary">
      <Heading size={3} textColor="primary" caps>
        User Experience neglect 😢
      </Heading>
      <List textColor="primary" padding="0 300px">
        <Appear>
          <ListItem>Enterprise software</ListItem>
        </Appear>
        <Appear>
          <ListItem>Dev tools</ListItem>
        </Appear>
      </List>
      <Notes>
        <ol>
          <li>some categories more neglected</li>
          <li>
            sure we get the latest toys, but... [“just” this, “just” that]
          </li>
          <li>We “just” accept it</li>
        </ol>
      </Notes>
    </Slide>
    <Slide transition={['fade']} bgColor="primary" textColor="tertiary">
      <Heading size={4} textColor="secondary" caps>
        Challenge complex solutions!
      </Heading>
      <Image src={images.simplify} width={800} />
      <Notes>
        <ol>
          <li>Cultivate good habits</li>
          <li>Better focus</li>
        </ol>
      </Notes>
    </Slide> */}
    <Slide transition={['fade']} bgColor="secondary" textColor="primary">
      <Heading size={3} textColor="primary" caps>
        Developer Experience (DX)
      </Heading>
      <Notes>
        <ol>
          <li>Passionate</li>
          <li>Specific area where DX neglected: [internationalization]</li>
        </ol>
      </Notes>
    </Slide>
    <Slide bgColor="primary">
      <Heading textColor="secondary" size={3} caps>
        Internationalization
      </Heading>
      <Notes>
        <ol>
          <li>
            first, we're going to improve the PX, the pronounciation experience
          </li>
          <li>bare with me...</li>
        </ol>
      </Notes>
    </Slide>
    <Slide bgColor="primary" textColor="secondary">
      <Heading size={3} textColor="secondary" caps>
        i18n
      </Heading>
      <Notes>
        <ol>
          <li>There!</li>
          <li>
            On a more serious note: how many of you have done some localization
            work in our products?
          </li>
          <li>How did you like the experience as a developer?</li>
          <li>In Sell we had our own struggles and decided to address them.</li>
        </ol>
      </Notes>
    </Slide>
    {/* <Slide transition={['fade']} bgColor="secondary">
      <BlockQuote>
        <Quote textColor="primary" textSize="40px" bold={false}>
          The design and development of a product, application or document
          content that enables easy localization for target audiences that vary
          in culture, region, or language
        </Quote>
        <Cite textAlign="right" textColor="tertiary">
          w3c
        </Cite>
      </BlockQuote>
    </Slide>
    <Slide transition={['fade']}>
      <Heading size={3} textColor="secondary" caps>
        The Process
      </Heading>
      <Appear>
        <Heading size={4} textColor="secondary">
          Extracting strings (phrases)
        </Heading>
      </Appear>
      <Appear>
        <Heading size={4} textColor="secondary">
          Preparing them for translators
        </Heading>
      </Appear>
      <Appear>
        <Heading size={4} textColor="secondary">
          Send off to translators
        </Heading>
      </Appear>
      <Appear>
        <Heading size={4} textColor="secondary">
          Download translations
        </Heading>
      </Appear>
      <Notes>
        <ol>
          <li>strings to key-value pairs</li>
          <li>API</li>
          <li>concatenations => interpolations placeholder, pluralization</li>
        </ol>
        <ol>
          <li>adding context, screenshots, avail. space</li>
          <li>it’s important</li>
        </ol>
        <ol>
          <li>formatting, date, RTL - acknowledge some challenges omitted</li>
          <li>focus: making the process more transparent</li>
        </ol>
      </Notes>
    </Slide> */}
    <Slide>
      {/* TODO: find better image? */}
      {/* TODO: perhaps I could start here? */}
      {/* QUESTION how many of you are have touched some part of localization for our products? */}
      <Image src={images.obstacles} width={800} />
      <Notes>
        <ol>
          <li>hit & miss</li>
          <li>allow the engineer to focus on what matters:</li>
          <li>
            Phrasing (no concatenations), quality, readability, well-tested code
          </li>
          <li>goals: to automate manual work, reduce context switches</li>
        </ol>
      </Notes>
    </Slide>
    <Slide transition={['fade']} bgColor="secondary">
      <Heading size={3} textColor="tertiary" caps>
        What we created
      </Heading>

      <List textColor="primary" padding="0 150px">
        <Appear>
          <ListItem>Added automated string extraction</ListItem>
        </Appear>
        <Appear>
          <ListItem>
            Auto-loading translations <br />
            just-in-time for the code that needs it
          </ListItem>
        </Appear>
        <Appear>
          <ListItem>Linter for strings</ListItem>
        </Appear>
      </List>

      {/* <Appear>
        <Heading size={4} textColor="primary">
          Added automated string extraction
        </Heading>
      </Appear>
      <Appear>
        <Heading size={4} textColor="primary">
          Auto-loading translations just-in-time for the code that needs it
        </Heading>
      </Appear>
      <Appear>
        <Heading size={4} textColor="primary">
          Linter for strings
        </Heading>
      </Appear> */}
      <Notes>
        <ol>
          <li>Going to focus on first 2, and how similar DX interventions</li>
          <li>reduce time teaching/onboarding</li>
        </ol>
      </Notes>
    </Slide>
    <Slide transition={['fade']}>
      <Heading size={3} textColor="secondary" caps>
        Let's see some code 🤓
      </Heading>
    </Slide>
    <Slide transition={['fade']} bgColor="secondary">
      <ExampleExtraction />
      <Notes>
        <ol>
          <li>simple example of a manually extracted string</li>
          <li>we have a piece of code that refers to a string by ID</li>
          <li>
            [NEXT] and the related file containing the source of truth (value,
            ctx)
          </li>
          <li>How would you improve the code? Any issues?</li>
        </ol>
        <ol>
          <li>[Code focus] Switch context JSON</li>
          <li>[Code focus] make ID for string</li>
          <li>[Code focus] Typo - different context in PR, might be missed</li>
          <li>Note: some IDEs</li>
          <li>[Code focus] Our solution</li>
          <li>[Question] Can something still be improved?</li>
          <li>[Code focus] V3 solution</li>
        </ol>
      </Notes>
    </Slide>
    <Slide bgColor="secondary">
      <Image src={images.magic} width={500} />
      <Notes>
        <ol>
          <li>Does anybody know?</li>
        </ol>
      </Notes>
    </Slide>
    <Slide bgColor="secondary">
      <Heading size={3} textColor="primary" caps>
        Abstract Syntax Tree
      </Heading>
      <Notes>
        <ol>
          <li>how many of you know?</li>
          <li>structural representation of your code</li>
          <li>Use to:</li>
          <li>extract information</li>
          <li>transform your code</li>
          <li>Think: babel, webpack</li>
        </ol>
      </Notes>
    </Slide>
    <Slide bgColor="secondary">
      <video src={images.showMe} width={700} autoPlay />
      <Notes>
        <a
          target="_blank"
          href="https://astexplorer.net/#/gist/6b7b9a681bcabaa9f89a5b4a97197efc/7d6a240c34bd663254c5998c19f2b0248ecf5539"
        >
          AST Explorer
        </a>
      </Notes>
    </Slide>
    <Slide bgColor="secondary">
      <Heading size={2} textColor="tertiary" caps>
        String extraction
      </Heading>
      <List textColor="primary" padding="0 100px" textSize="1.5rem">
        <Appear>
          <div style={{marginBottom: '15px'}}>
            <ListItem textSize="2rem">
              find all the calls to the 't' function imported from 'i18n'
              <CodePane
                lang="js"
                source={`import t from 'i18n'
const value = t('Hi Zendesk!')`}
                style={{fontSize: '2rem'}}
              />
            </ListItem>
          </div>
        </Appear>
        <Appear>
          <div style={{marginBottom: '15px'}}>
            <ListItem textSize="2rem">
              for each call: generate a unique, stable key
              <CodePane
                lang="js"
                source={`'hi#A4B1F9'`}
                style={{fontSize: '2rem'}}
              />
            </ListItem>
          </div>
        </Appear>
        <Appear>
          <ListItem textSize="2rem">
            stash the key-value pair into a file for translators
          </ListItem>
        </Appear>
        <Appear>
          <div>
            <ListItem textSize="2rem">
              replace the arguments passed to the 't' function
            </ListItem>

            <CodePane
              lang="js"
              source={`import t from 'i18n'
const value = t('hi#A4B1F9')`}
              style={{fontSize: '2rem'}}
            />
          </div>
        </Appear>
      </List>
      <Notes>
        <ol>
          <li>breaking it down</li>
        </ol>
      </Notes>
    </Slide>
    <Slide bgColor="secondary">
      <Heading size={2} textColor="primary" caps>
        Complex Cases
      </Heading>
      <Appear>
        <div>
          <Heading size={4} textColor="tertiary" caps>
            Pluralization
          </Heading>
          <Heading size={5} textColor="tertiary" caps>
            1 żarów<u>ka</u>, 2 żarów<u>ki</u>, 5 żarów<u>ek</u>
          </Heading>
        </div>
      </Appear>
      <Notes>
        <ol>
          <li>
            I’ve only shown you a simple case with interpolated variables,
            There’s more!
          </li>
          <li>
            multiple versions of a phrase, depending on the quantity of
            something
          </li>
          <li>
            In Polish for example the ending of a noun will change depending on
            its quantity [CLICK]
          </li>
          <li>zarowka - in polish - bulb</li>
          <li>Traditionally, you need keys for all required cases</li>
          <li>
            here’s how we might take this burden off the developer via automatic
            extraction. [EXAMPLE]
          </li>
        </ol>
      </Notes>
    </Slide>
    {/* <Slide bgColor="secondary">
      <Heading size={3} textColor="primary" caps>
        Let's see code!
      </Heading>
    </Slide> */}
    <Slide bgColor="secondary">
      <ExampleExtractionPlural />
      <Notes>
        <ol>
          <li>[EXAMPLE]</li>
          <li>Then there’s things like gender or pronouns [EXAMPLE]</li>
          <li>Traditionally they are very complex to extract manually</li>
          <li>Not implemented yet</li>
        </ol>
      </Notes>
    </Slide>
    <Slide bgColor="secondary">
      <ExampleExtractionICU />
      <Notes>
        <ol>
          <li>ICU Project - International Components for Unicode</li>
          <li>
            If interested Mozilla has been working on a language Project Fluent
          </li>
          <li>That was how we simplified the extraction process</li>
        </ol>
      </Notes>
    </Slide>
    <Slide bgColor="secondary">
      <Heading size={2} textColor="primary" caps>
        Solution
      </Heading>
      <Notes>
        <ol>
          <li>As we’re building - extract information.</li>
          <li>After - create deliverables for translators</li>
        </ol>
      </Notes>
    </Slide>
    {/* <Slide bgColor="secondary">
      <Heading size={3} textColor="primary" caps>
        v1: <br />
        babel transformation
      </Heading>
      <Notes>
        <ol>
          <li>Passing data between babel and our builder wasn’t nice</li>
          <li>webpack parses the code into an AST anyway</li>
          <li>No dependency tree in babel, we need it for autoloading</li>
        </ol>
      </Notes>
    </Slide> */}
    <Slide bgColor="secondary">
      <Heading size={3} textColor="primary" caps>
        {/* v2: <br /> */}
        webpack plugin
      </Heading>
      <Notes>
        <ol>
          <li>Initially a babel plugin, but had it's issues</li>
          <li>Purely a webpack plugin</li>
          <li>Challenge: no good documentation</li>
          <li>dependency tree - yeey - it opens doors to autoloading</li>
        </ol>
      </Notes>
    </Slide>
    <Slide bgColor="secondary">
      <Heading size={3} textColor="primary" caps>
        Loading translations
      </Heading>
      {/* <Image src={images.biceps} width={400} /> */}
      <ExampleLoading />
      <Notes>
        <ol>
          <li>Your app isn’t trivial, you don’t load everything upfront</li>
          <li>Solution: code-splitting</li>
          <li>In most i18n Concept of namespaces</li>
          <li>And Asynchronous loading of the required namespace</li>
          <li>[EXAMPLE]</li>
          <li>
            Here: A module that displays a message from the namespace “moduleC”
          </li>
          <li>We have to load that set of translations in an NS</li>
          <li>Usually fetch a JSON file</li>
          <li>
            Leaky abstraction, you need to know a lot about what you're loading
          </li>
          <li>
            If Forget about the NS - you won’t display some of the translations
          </li>
          <li>[question] How could this be better? [next]</li>
        </ol>
        <ol>
          <li>[props - hard paper]</li>
          <li>most web apps use a bundler</li>
          <li>we plugged into the chunk loading mechanism</li>
          <li>
            Thanks to the dependency tree we can make a list of required
            namespaces for each chunk
          </li>
          <li>autoload in parallel to the chunk, before it’s executed</li>
        </ol>
      </Notes>
    </Slide>
    <Slide bgColor="secondary">
      <Heading size={3} textColor="primary" caps>
        Pros and cons
      </Heading>
      <Appear>
        <Heading size={5} textColor="tertiary">
          Easy to use localized strings anywhere
        </Heading>
      </Appear>
      <Appear>
        <Heading size={5} textColor="tertiary">
          No need to think about the keys, namespaces, loading
        </Heading>
      </Appear>
      <Appear>
        <Heading size={5} textColor="quaternary">
          Can't change language without fully reloading the page
        </Heading>
      </Appear>

      <Notes>
        <ol>
          <li>(last) language change wasn't a requirement for us</li>
          {/* <li>choose the key algorithm wisely, it'll be hard to migrate</li> */}
        </ol>
      </Notes>
    </Slide>
    {/* <Slide bgColor="secondary">
      <Heading size={3} textColor="primary" caps>
        Open Source
      </Heading>
      <Appear>
        <Heading size={5} textColor="tertiary">
          ...soon
        </Heading>
      </Appear>
      <Appear>
        <Heading size={5} textColor="tertiary">
          ...ish
        </Heading>
      </Appear>
    </Slide> */}
    <Slide bgColor="primary">
      <Heading size={3} textColor="secondary" caps>
        Ending thoughts
      </Heading>
      <Appear>
        <Heading size={5} textColor="secondary">
          Good DX is: less context switches, simpler APIs, happier developers
        </Heading>
      </Appear>
      <Appear>
        <Heading size={5} textColor="secondary">
          Code transformations ≠ fancy new syntax
        </Heading>
      </Appear>
      <Appear>
        <Heading size={5} textColor="secondary">
          Tooling can be your friend
        </Heading>
      </Appear>
      <Appear>
        <Heading size={5} textColor="secondary">
          Simplify
        </Heading>
      </Appear>
      <Notes>
        <ol>
          <li>I want to leave you with some thoughts</li>
          <li>
            You can leverage the builder and the dependency tree to tackle
            complex issues like i18n
          </li>
          <li>Don’t take things for granted - simplify the experience</li>
        </ol>
      </Notes>
    </Slide>
    <Slide bgColor="secondary">
      <Heading size={3} textColor="primary" caps>
        Questions?
      </Heading>
      <br />
      <br />
      <Text margin="10px 0 0" textColor="tertiary" textAlign="right">
        @niieani
      </Text>
      {/* <Text margin="10px 0 0" textColor="tertiary" textAlign="right">
        @getbaselab
      </Text> */}
      <Text margin="10px 0 0" textColor="tertiary" bold textAlign="right">
        Bazyli Brzóska / Zendesk
      </Text>
    </Slide>
    <IntroSlide bgColor="quaternary">
      <LeftPart>
        <Image src={images.outro1} />
      </LeftPart>

      <MiddlePart>
        <Image src={images.outro2} />
      </MiddlePart>

      <RightPart>
        <Image src={images.outro3} />
      </RightPart>
    </IntroSlide>
    <Slide bgColor="primary">
      <Heading size={3} textColor="secondary" caps>
        Thank you!
      </Heading>
    </Slide>
  </Deck>
))
