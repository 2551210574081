import React, {useEffect, useState, useMemo} from 'react'
import useSpring from 'react-use/lib/useSpring'
// import Swipeable from "react-swipeable";
import Slide from 'githistory-web/src/slide'
import keycode from 'keycode'
import {getSlides} from 'githistory-web/src/differ'

// slideLines = getSlides(codes, language)

export function CodeSlides({
  codes,
  language,
}: {
  codes: Array<string>
  language: 'js'
}) {
  const slideLines = useMemo<Array<Array<string>>>(
    () => getSlides(codes.slice().reverse(), language),
    [codes, language],
  )
  const [current, target, setTarget] = useSliderSpring(slideLines.length - 1)
  const setClampedTarget = (newTarget: number) => {
    setTarget(Math.min(slideLines.length - 0.75, Math.max(-0.25, newTarget)))
  }

  const index = Math.round(current)
  const nextSlide = () => setClampedTarget(Math.round(target - 0.51))
  const prevSlide = () => setClampedTarget(Math.round(target + 0.51))

  useEffect(() => {
    function onKeyDown(e: KeyboardEvent) {
      e.preventDefault()
      e.stopImmediatePropagation()
      if (e.keyCode === keycode.codes.down) {
        nextSlide()
      } else if (e.keyCode === keycode.codes.up) {
        prevSlide()
      }
      // else if (e.keyCode === 32) {
      //   setClampedTarget(current);
      // }
    }
    window.addEventListener('keydown', onKeyDown)
    return () => window.removeEventListener('keydown', onKeyDown)
  })

  return <Slide time={index - current} lines={slideLines[index]} />
}

function useSliderSpring(initial: number) {
  const [target, setTarget] = useState(initial)
  const tension = 0
  const friction = 10
  const value = useSpring(target, tension, friction)
  return [Math.round(value * 100) / 100, target, setTarget] as [
    number,
    number,
    typeof setTarget
  ]
}
