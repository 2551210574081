import React from 'react'
// import History from 'githistory-web/src/history'
import './public.css'
import example1 from '!!raw-loader!../examples/extraction/example-1'
import example1b from '!!raw-loader!../examples/extraction/example-1b'
import example2 from '!!raw-loader!../examples/extraction/example-2'
import {CodeSlides} from './slider/slider'

export const CodeSlide = ({codes}: {codes: Array<string>}) => (
  <div
    className="git-history-custom"
    style={{
      fontSize: '14px', // 'initial'
      textAlign: 'left',
      lineHeight: 1,
      overflow: 'scroll',
      maxHeight: '90vh',
      // width: '74vw',
      // margin: '10px auto',
    }}
  >
    <CodeSlides codes={codes} language="js" />
  </div>
)

export const ExampleExtraction = () => (
  <CodeSlide
    codes={[
      require('!!raw-loader!../examples/extraction/example-0'),
      require('!!raw-loader!../examples/extraction/example-1'),
      require('!!raw-loader!../examples/extraction/example-1a'),
      require('!!raw-loader!../examples/extraction/example-1b'),
      require('!!raw-loader!../examples/extraction/example-1c'),
      require('!!raw-loader!../examples/extraction/example-2'),
      require('!!raw-loader!../examples/extraction/example-3'),
    ]}
  />
)

export const ExampleExtractionICU = () => (
  <CodeSlide
    codes={[
      require('!!raw-loader!../examples/extraction-icu/example.0'),
      require('!!raw-loader!../examples/extraction-icu/example.1'),
      require('!!raw-loader!../examples/extraction-icu/example.2'),
    ]}
  />
)

export const ExampleExtractionPlural = () => (
  <CodeSlide
    codes={[
      require('!!raw-loader!../examples/extraction-plural/example-1'),
      require('!!raw-loader!../examples/extraction-plural/example-2'),
      require('!!raw-loader!../examples/extraction-plural/example-3'),
      require('!!raw-loader!../examples/extraction-plural/example-4'),
    ]}
  />
)

export const ExampleLoading = () => (
  <CodeSlide
    codes={[
      require('!!raw-loader!../examples/loading/example-01'),
      require('!!raw-loader!../examples/loading/example-02'),
      require('!!raw-loader!../examples/loading/example-03'),
      require('!!raw-loader!../examples/loading/example-03a'),
      require('!!raw-loader!../examples/loading/example-04'),
      require('!!raw-loader!../examples/loading/example-05'),
    ]}
  />
)

// const defaults = {
//   author: {},
//   date: new Date(),
//   message: '',
// }

// export const Extraction2 = () => (
//   <div
//     className="git-history-custom"
//     style={{fontSize: 'initial', textAlign: 'left', lineHeight: 1}}
//   >
//     <History
//       commits={[
//         {
//           ...defaults,
//           content: example1,
//         },
//         {
//           ...defaults,
//           content: example1b,
//         },
//         {
//           ...defaults,
//           content: example2,
//         },
//       ]}
//       language="js"
//       loadMore={() => {}}
//     />
//   </div>
// )

// import CodeSlide from 'spectacle-code-slide'
// const code =
// <CodePane
//   // bgColor="primary"
//   // transition={[]}
//   lang="js"
//   source={require("!!raw-loader!../examples/extraction/example-1")}
//   // ranges={[
//   //   { loc: [0, 270], title: "Walking through some code" },
//   //   { loc: [0, 1], title: "The Beginning" },
//   //   { loc: [1, 2] },
//   //   { loc: [1, 2], note: "Heres a note!" },
//   //   { loc: [2, 3] },
//   //   // { loc: [4, 7], image: shiaLabeoufMagicGif },
//   //   { loc: [8, 10] },
//   // ]}
// />
